<template>
  <v-form :value="value" v-bind="$attrs" @input="onValidForm">
    <slot />
  </v-form>
</template>

<script>
export default {
  name: "WrapperForm",
  props: {
    value: { type: Boolean },
    model: { type: Object },
  },
  data() {
    return {};
  },
  watch: {
    model: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    onValidForm(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style></style>
