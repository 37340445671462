<template>
  <v-card tile shaped :loading="loading">
    <v-card-text class="pt-4">
      <wrapper-form
        v-bind="$attrs"
        v-if="userDetail"
        :model="userDetail"
        @input="onInputForm"
      >
        <v-row>
          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Nombre"
              v-model="userDetail.first_name"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Apellido"
              v-model="userDetail.last_name"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="DNI"
              v-model="userDetail.dni"
              :rules="[validators.integerValidator]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="CUIL/CUIT"
              v-model="userDetail.cuil"
              :rules="[validators.integerValidator]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Fecha de nacimiento"
              v-model="userDetail.date_of_birth"
              :rules="[validators.required]"
              type="date"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Provincia"
              v-model="userDetail.province"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Ciudad"
              v-model="userDetail.city"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Calle y número domicilio"
              placeholder=""
              v-model="userDetail.address"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <SelectCountries
              v-model="userDetail.country"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Teléfono"
              v-model="userDetail.phone"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <FormTextField
              label="Whatsapp"
              v-model="userDetail.whatsapp"
              :rules="[validators.required]"
            />
          </v-col>

          <v-col xs="12" sm="12" lg="6" cols="12">
            <SelectCareers
              v-if="userTypeStudent"
              v-model="userDetail.career"
              :rules="[validators.required]"
            />
          </v-col>
        </v-row>
      </wrapper-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, watch, getCurrentInstance, computed } from "@vue/composition-api";
import FormTextField from "@/components/forms/TextField";
import FormSelectField from "@/components/forms/Select";
import api from "@/services";
import debounce from "lodash.debounce";
import { required, integerValidator } from "@core/utils/validation";
import SelectCountries from "@/components/general/SelectCountries";
import SelectCareers from "@/components/general/SelectCareers";
import WrapperForm from "@/components/general/WrapperForm";
import FormTextDatePicker from "./TextDatePicker.vue";
import store from "@/store";

export default {
  name: "FormPersonalInfo",
  components: {
    FormTextField,
    FormSelectField,
    SelectCountries,
    SelectCareers,
    WrapperForm,
    FormTextDatePicker,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;

    const loading = ref(false);

    const userDetail = computed(() => store.getters["user/userDetail"]);
    const userTypeStudent = computed(
      () => store.getters["auth/userTypeStudent"]
    );

    const onInputForm = (v) => {
      emit("input", v);
    };

    const onToggleLoading = (value = null) => {
      loading.value = value || !loading.value;
    };

    const onChangeDate = (d) => {
      userDetail.value["date_of_birth"] = d;
    };

    const updateMeDetail = async () => {
      try {
        onToggleLoading();

        await api.updateMeDetail(userDetail.value);
      } catch (error) {
        const { data } = error.response;
        const { message = null } = data;

        vm.$alert(message, null, "error");
      } finally {
        onToggleLoading();
      }
    };

    watch(
      userDetail,
      debounce(() => {
        updateMeDetail();
      }, 1000),
      {
        deep: true,
      }
    );

    return {
      userDetail,
      loading,

      validators: {
        required,
        integerValidator,
      },

      updateMeDetail,
      onInputForm,
      onChangeDate,
    };
  },
};
</script>

<style></style>
