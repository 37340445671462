<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12" sm="4" md="3">
          <vue-qr
            :logoSrc="appLogo"
            :text="QR"
            :size="200"
            v-if="userData && userLinkProfile"
          ></vue-qr>
        </v-col>
        <v-col cols="12" xs="12" sm="8" md="9" style="padding: 40px;">
          <h2 class="">Guarda tu Código QR</h2>
          <p>
            Muestra la información de tu perfil, si cargaste todo los datos solicitados. 
          </p>
          <a :href="userDocument" target="_blank" download> Descargar pdf </a>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed, onMounted, ref } from "@vue/composition-api";
import VueQr from "vue-qr";
import themeConfig from "@themeConfig";
import api from "@/services";

export default {
  name: "QrCodeUser",
  components: {
    VueQr,
  },
  setup(props) {
    const userData = computed(() => {
      return JSON.parse(localStorage.getItem("userData"));
    });

    let userDocument = ref(null);
    let QR = ref(null);
    const baseURL = computed(() => {
      return process.env.VUE_APP_BASE_URL;
    });

    const userLinkProfile = computed(() => {
      const { link_user_validation = null } = userData.value;
      return link_user_validation;
    });

    const getDoc = async () => {
      const { data: response } = await api.getMe();
        const { success, data } = response;
        console.log("response", data)
        userDocument.value = data.pdf;
        QR.value = data.qr
    }

    onMounted(async () => {
      getDoc()
    });

    return {
      appLogo: themeConfig.app.logo,
      userData,
      userLinkProfile,
      userDocument,
      QR
    };
  },
};
</script>

<style></style>
